// Bootstrap Framework
import "./scss/index.scss";
const jQuery = require('jquery');
window.$ = window.jQuery = jQuery;
import "popper.js";
import "bootstrap";

import "slick-carousel";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import fancybox from "@fancyapps/fancybox";
import swal from 'sweetalert2';
window.swal = window.Swal = swal;

$(function () {

    //#region bootstrap navbar + hamburger + body scroll lock
    $("#navbarMain").on("show.bs.collapse", function (e) {
        $(".navbar-toggler .hamburger").addClass("is-active");
        //let scrollableNav = document.querySelector("#navbarMain");
        //disableBodyScroll(scrollableNav);
    });
    $("#navbarMain").on("hidden.bs.collapse", function (e) {
        $(".navbar-toggler .hamburger").removeClass("is-active");
        //let scrollableNav = document.querySelector("#navbarMain");
        //enableBodyScroll(scrollableNav);
    });
    //#endregion

    //#region searchbar toggle
    $('.searchbar-toggle').on('click', function (e) {
        e.preventDefault();
        $('.searchbar').toggleClass('show');
    });
    //#endregion

    //#region slider
    $(".slider").slick({
        fade: true,
        prevArrow: ".slider__arrow--prev",
        nextArrow: ".slider__arrow--next",
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    dots: true,
                },
            },
        ],
    });
    //#endregion

    //#region details colors
    $(".details__colors > a").each(function () {
        let color = $(this).data("color");
        $(this).css("background-color", color);
    });
    //#endregion

    //#region details gallery
    $(".gallery").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        adaptiveHeight: true,
        asNavFor: ".gallery__thumbs",
    });
    $(".gallery__thumbs").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: ".gallery",
        dots: false,
        arrows: true,
        focusOnSelect: true
    });
    //#endregion

    //#region carousel
    $(".carousel").slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });
    //#endregion

    //#region variante colore prodotto
    $(".prd-color").on('click', function (e) {
        e.preventDefault();

        //var DataColor = $(this).attr("data-color"); //Hex
        var DataId = $(this).attr("data-id"); //ID Elemento Variante
        $(".prd-color").each(function () {
            $(this).removeClass("active");
        });

        $(this).addClass("active");
        $("[id*=hidVarianteColore]").val(DataId);

        //console.log('ho cliccato una variante colore');
        //console.log(DataColor);
    });
    //#endregion

});
